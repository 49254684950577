<!-- 商品管理右边区域 -->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="name"
        placeholder="请输入商品名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-input
        v-model="title"
        placeholder="请输入店铺名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>

      <el-button type="primary" style="margin-left: 5px" @click="add()"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column prop="id" type="index" label="ID" width="60">
      </el-table-column>
      <el-table-column prop="name" label="剧本名" width="150">
      </el-table-column>
      <el-table-column prop="image" label="剧本封面">
        <template slot-scope="scope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scope.row.image"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="peoples" label="人物介绍" width="200">
        <template slot-scope="scope">
          <div
            v-for="item in scope.row.peoples"
            :key="item.id"
            style="display: flex; margin-top: 3px"
          >
            <img
              style="width: 50px; height: 50px"
              :src="$httpUrl + '/image/' + item.image"
              alt=""
            />
            <el-input
              :disabled="true"
              style="width: 100px; margin-left: 10px; margin-top: 7px"
              v-model="item.name"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="boy" label="男生数" width="80"> </el-table-column>
      <el-table-column prop="girl" label="女生数" width="80"> </el-table-column>
      <el-table-column prop="duration" label="时长（单位H）" width="110">
      </el-table-column>
      <el-table-column prop="level" label="难度" width="80"> </el-table-column>
      <el-table-column prop="puttime" label="上架时间" width="150">
      </el-table-column>
      <el-table-column prop="specs" label="规格" width="180"> </el-table-column>
      <el-table-column prop="number" label="适合人数" width="80">
      </el-table-column>
      <el-table-column prop="type" label="题材" width="180"> </el-table-column>
      <el-table-column prop="grade" label="综合评分" width="100">
      </el-table-column>
      <el-table-column prop="context" label="剧本介绍" width="180">
      </el-table-column>
      <el-table-column prop="operate" label="" width="180">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      title="提示"
      width="80%"
      :visible.sync="centerDialogVisible"
      center
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <div style="display: flex; flex-flow: wrap">
          <el-form-item label="名称" prop="name">
            <el-col :span="10">
              <el-input v-model="form.name" style="width: 300px"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="男生数" prop="boy">
            <el-input v-model="form.boy" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="女生数" prop="girl">
            <el-input v-model="form.girl" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="时长" prop="duration">
            <el-input v-model="form.duration" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="难度" prop="level">
            <el-select v-model="form.level" placeholder="请选择难度">
              <el-option label="新手" value="新手"></el-option>
              <el-option label="进阶" value="进阶"></el-option>
              <el-option label="硬核" value="硬核"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="剧本封面" prop="fenmian">
          <el-col :span="20">
            <imgUploader
              :iga="form.fenmian"
              ref="pic1"
              :width="120"
              :height="180"
              :limit="1"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="人物介绍" prop="images">
          <el-col :span="20">
            <imgUploader
              :iga="form.images"
              ref="pic2"
              :width="120"
              :height="180"
              :limit="15"
              @upload="($event) => uploadimgone()"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="图片名称" prop="names">
          <el-col :span="20">
            <el-input
              v-for="(item, index) in form.names"
              :key="index"
              v-model="form.names[index]"
              style="width: 120px; margin-left: 11px; margin-top: 11px"
            ></el-input>
          </el-col>
        </el-form-item>
        <div style="display: flex; flex-flow: wrap">
          <el-form-item label="剧本介绍" prop="context">
            <el-input
              type="textarea"
              style="width: 800px"
              v-model="form.context"
            ></el-input>
          </el-form-item>
          <el-form-item label="规格" prop="specs">
            <el-col :span="10" style="width: 200px">
              <el-select
                v-model="form.specs"
                multiple
                placeholder="请选择"
                @change="changespecs"
              >
                <el-option
                  v-for="item in specs"
                  :key="item.title"
                  :label="item.title"
                  v-model="item.title"
                  :class="[item.selected ? 'selected' : '']"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="适合人数" prop="number">
            <el-col :span="10" style="width: 70px">
              <el-input v-model="form.number"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="题材" prop="type">
            <el-col :span="10" style="width: 200px">
              <el-select
                v-model="form.type"
                multiple
                placeholder="请选择"
                @change="changetype"
              >
                <el-option
                  v-for="item in type"
                  :key="item.title"
                  :label="item.title"
                  :value="item.title"
                  :class="[item.selected ? 'selected' : '']"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  name: "GoodstypeManage",
  components: {
    imgUploader,
  },
  data() {
    return {
      tableData: [
        {
          id: "1",
          name: "苍天饶过谁",
          price: "$199",
          boy: "3",
          girl: "3",
          duration: "3-5",
          level: "新手",
          image: "1677215830973.jpeg",
          puttime: "2023-03-08 20:11:59",
          grade: "8.7",
          peoples: [],
          context: "ghiosdfjlakhna",
          specs: "盒装,独家,实景",
          number: 5,
          type: "情感，恐怖，推理",
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      name: "",
      title: "",
      specs: [],
      type: [],
      centerDialogVisible: false,
      form: {
        id: "",
        name: "",
        price: 0,
        boy: "",
        girl: "",
        duration: "",
        level: "",
        fenmian: [],
        puttime: "",
        images: [],
        names: [],
        grade: 0,
        context: "",
        specs: [],
        number: 0,
        type: [],
      },
      rules: {
        name: [{ required: true, message: "请输入剧本名", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        boy: [{ required: true, message: "请输入男生数", trigger: "blur" }],
        girl: [{ required: true, message: "请输入女生数", trigger: "blur" }],
        duration: [
          {
            required: true,
            message: "请输入游戏时长（5-6小时）",
            trigger: "blur",
          },
        ],
        level: [{ required: true, message: "请输入剧本难度", trigger: "blur" }],
        fenmian: [{ required: true, message: "请输入", trigger: "blur" }],
        images: [{ required: true, message: "请输入", trigger: "blur" }],
        names: [{ required: true, message: "请输入", trigger: "blur" }],
        number: [
          { required: true, message: "请输入适合人数", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    // 4、 监听对象中的 某个属性
    "form.images": {
      async handler(newVal, oldVal) {
        console.log("++++++++");
        console.log(oldVal.length);
        // console.log(this.$refs.pic2);
        if (oldVal.length > 0) {
          let b = this.$refs.pic2.abc;
          if (b != -1) {
            this.form.names.splice(b, 1);
          } else {
            console.log(this.form.names.length);
            console.log(oldVal);
            console.log(newVal);
            // if (newVal.length != oldVal.length)
            if (this.form.names.length < oldVal.length)
              this.form.names[newVal.length - 1] = "";
          }
        }
      },
    },
  },
  methods: {
    changetype() {
      console.log(this.form.type);
      console.log(this.type);
      let type = [];
      this.type.forEach((x) => {
        let out_x = x;
        if (
          this.form.type.filter((y) => {
            return y.trim() == out_x.title;
          }).length > 0
        ) {
          out_x.selected = true;
        } else {
          out_x.selected = false;
        }
        type.push(out_x);
      });
      this.type = type;
      console.log(this.type);
    },
    changespecs() {
      console.log(this.form.specs);
      console.log(this.specs);
      let specs = [];
      this.specs.forEach((x) => {
        let out_x = x;
        if (
          this.form.specs.filter((y) => {
            return y.trim() == out_x.title;
          }).length > 0
        ) {
          out_x.selected = true;
        } else {
          out_x.selected = false;
        }
        specs.push(out_x);
      });
      this.specs = specs;
      console.log(this.specs);
    },
    uploadimgone(v) {
      this.form.images = this.$refs.pic2.imgUrl;
    },
    save() {
      // 是否 有未填项

      if (
        this.form.name == "" ||
        this.form.boy == "" ||
        this.form.girl == "" ||
        this.form.duration == "" ||
        this.form.level == "" ||
        this.form.fenmian.length == 0 ||
        this.form.images.length[0] == ""
      ) {
        this.$message({
          message: "未完整输入带*的必填项",
          type: "warning",
        });
        return;
      }
      // 图片名称是否 填了
      if (this.form.names.filter((x) => x == "").length > 0) {
        this.$message({
          message: "未完整输入图片的名称",
          type: "warning",
        });
        return;
      }
      // 封装images的 base64数据
      let images = [];
      this.form.images.forEach((x) => {
        images.push(x.base64);
      });
      let a = {
        id: this.form.id,
        name: this.form.name,
        price: this.form.price,
        boy: this.form.boy,
        girl: this.form.girl,
        duration: this.form.duration,
        level: this.form.level,
        fenmian: this.form.fenmian[0].base64,
        puttime: "",
        images: images,
        names: this.form.names,
        grade: 0,
        context: this.form.context,
        specs: this.form.specs,
        number: this.form.number,
        type: this.form.type,
        recommend: this.form.recommend,
        hot: 0,
        newly: this.form.newly,
      };

      // let flag = false;
      // if (!flag) {
      //   return;
      // }
      this.$axios
        .post(this.$httpUrl + "/drama/addUpdate", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
          this.loadPost();
        });
    },
    //上下架商品
    shelf(id) {
      this.$axios
        .get(this.$httpUrl + "/goods/out/" + id)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.loadPost();
          }
        });
    },
    // 删除商品
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/drama/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    // 编辑商品
    mod(row) {
      this.centerDialogVisible = true;
      // let flag = false;
      // if (!flag) {
      //   return;
      // }
      // 修改 组件的 b 值 防止错误删除
      console.log("*******");
      // console.log(this.$refs.pic2);
      this.$refs.pic2.abc = -1;
      // 封装 图片的前缀
      let images = [];
      let names = [];

      row.peoples.forEach((element) => {
        images.push({ base64: this.$httpUrl + "/image/" + element.image });
        names.push(element.name);
      });

      this.form.fenmian = [];
      this.form.images = [];
      this.form.names = [];
      (this.form.id = row.id),
        (this.form.name = row.name),
        (this.form.price = row.price),
        (this.form.boy = row.boy),
        (this.form.girl = row.girl),
        (this.form.duration = row.duration),
        (this.form.level = row.level),
        (this.form.type = row.type),
        this.form.fenmian.push({
          base64: this.$httpUrl + "/image/" + row.image,
        }),
        (this.form.puttime = ""),
        (this.form.images = images),
        (this.form.names = names),
        (this.form.grade = row.grade),
        (this.form.context = row.context),
        (this.form.specs = row.specs),
        (this.form.number = row.number);
      // 封装 默认选中 option
      // console.log(this.type);
      // console.log(this.form.type);
      let types = [];
      this.type.forEach((x) => {
        let out_x = x;
        if (
          this.form.type.filter((y) => {
            return y.trim() == out_x.title;
          }).length > 0
        ) {
          out_x.selected = true;
        }
        types.push(out_x);
      });
      this.type = types;
      let specs = [];
      this.specs.forEach((x) => {
        let out_x = x;
        if (
          this.form.specs.filter((y) => {
            return y.trim() == out_x.title;
          }).length > 0
        ) {
          out_x.selected = true;
        }
        specs.push(out_x);
      });
      this.specs = specs;
      console.log(row.peoples);
      console.log(this.form.names);
      // this.centerDialogVisible = true;
    },
    // 新增商品
    add() {
      this.form = {
        id: "",
        name: "",
        price: 0,
        boy: "",
        girl: "",
        duration: "",
        level: "",
        fenmian: [],
        images: [],
        names: [],
        puttime: "",
        grade: 0,
        context: "",
        specs: [],
        number: 0,
        type: [],
      };
      this.names = [];
      // this.form = {
      //   id: "",
      //   name: "撒公司",
      //   price: 55,
      //   boy: 2,
      //   girl: 3,
      //   duration: "5-7小时",
      //   level: "进阶",
      //   fenmian: [],
      //   images: [],
      //   names: [],
      //   puttime: "",
      //   grade: 0,
      //   context: "啊生活的如果",
      //   specs: [1, 2],
      //   number: 0,
      //   type: [3, 2],
      //   recommend: true,
      //   hot: 0,
      //   newly: false,
      // };
      // 清空 默认选中
      let types = [];
      this.type.forEach((x) => {
        let out_x = x;
        out_x.selected = false;
        types.push(out_x);
      });
      this.type = types;
      let specs = [];
      this.specs.forEach((x) => {
        let out_x = x;
        out_x.selected = false;
        specs.push(out_x);
      });
      this.specs = specs;
      this.centerDialogVisible = true;
      console.log(this.names.length);
      console.log(this.form.names.length);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
      this.title = "";
    },
    // 查询商品
    loadPost() {
      this.tableData = [];
      let a = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          name: this.name,
        },
      };

      this.$axios
        .post(this.$httpUrl + "/drama/pagelist", a)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            // console.log(this.tableData);
          }
        });
    },
    loadlable() {
      this.$axios
        .get(this.$httpUrl + "/label/type")
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.specs = res.data.specs;
            console.log(this.specs);
            this.type = res.data.type;
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
    this.loadlable();
    this.centerDialogVisible = true;
    this.centerDialogVisible = false;
  },
};
</script>

<style scoped>
</style>